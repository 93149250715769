





















































































































import Vue from "vue";

export default Vue.extend({
  name: "footerComponet",
  props: {
    navegation: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    nameNewsletter: "",
    emailNewsletter: "",
    selectItem: null,
  }),
  methods: {
    clear() {
      this.selectItem = null;
    },
  },
});
