






































































































































































































































import Vue from "vue";
// import Background from '@/components/Background.vue';
import Footer from "@/components/Footer.vue";
import Contact from "@/components/Contact.vue";

export default Vue.extend({
  name: "App",

  components: {
    // Background,
    Footer,
    Contact,
  },
  mounted() {
    this.height =
      window.innerHeight - ((this.$refs.header as any).$el as HTMLElement).clientHeight;
  },
  methods: {
    clear() {
      this.selectItem = null;
    },
  },

  data: () => ({
    height: 850,
    closeOnClick: true,
    selectItem: null,
    navegation: [
      { title: "Inicio", ref: "#home", hover: false },
      { title: "Servicios", ref: "#services", hover: false },
      { title: "Nosotros", ref: "#our_business", hover: false },
      // {title: "Clientes", ref:"#clients", hover: false},
      { title: "Contacto", ref: "#contact_us", hover: false },
    ],
  }),
});
