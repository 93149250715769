



































































































































import Vue from "vue";
import emailjs from "emailjs-com";

export default Vue.extend({
  data: () => ({
    valid: false,
    dialog: false,
    sending: false,
    titleDialog: "",
    textDialog: "",
    formObj: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
    nameRules: [
      (v: any) => !!v || "El nombre es requerido",
      (v: any) => v.length <= 30 || "El nombre puede tener como máximo 30 caracteres",
    ],
    phoneRules: [
      (v: any) => !v || /^[\+]?[0-9]{10,13}$/im.test(v) || "Teléfono no válido ",
    ],
    emailRules: [
      (v: any) => !!v || "E-mail es requerido",
      (v: any) => v.length <= 50 || "El email puede tener como máximo 50 caracteres",
      (v: any) =>
        /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i.test(
          v
        ) || "E-mail no es válido",
    ],
    messageRules: [
      (v: any) => !!v || "El mensaje es requerido",
      (v: any) => v.length <= 280 || "El mensaje puede tener como máximo 280 caracteres",
    ],
  }),
  created() {
    emailjs.init("user_2i3mkaZxwVAZa6F3BPvXY");
  },
  methods: {
    submit() {
      this.sending = true;
      (this.$refs.form as HTMLFormElement).validate();
      if (this.valid) {
        emailjs
          .send("service_ay5imnm", "template_2hla70b", this.formObj)
          .then(
            () => {
              this.titleDialog = "Mensaje enviado correctamente";
              this.textDialog = "A la brevedad nos contactaremos";
            },
            () => {
              this.titleDialog = "Error";
              this.textDialog =
                "Ocurrio un error, por favor intente nuevamente a la brevedad.";
            }
          )
          .then(() => {
            this.dialog = true;
            this.sending = false;
          });
      }
    },
    resetValidation() {
      (this.$refs.form as HTMLFormElement).resetValidation();
    },
  },
});
